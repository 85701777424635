body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: linear-gradient(to left, rgba(7, 27, 82, 1) 0%, rgba(0, 128, 128, 1) 100%);
}

@font-face {
  font-family: 'SEGA LOGO FONT';
  font-style: normal;
  font-weight: normal;
  src: local('SEGA LOGO FONT'), url('./assets/SEGA.woff') format('woff');
}

h1 {
  font-family: 'SEGA LOGO FONT';
  color: #0ccac4;
  font-weight: 200;
}
